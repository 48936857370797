exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-film-tsx": () => import("./../../../src/pages/film.tsx" /* webpackChunkName: "component---src-pages-film-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tattoo-tsx": () => import("./../../../src/pages/tattoo.tsx" /* webpackChunkName: "component---src-pages-tattoo-tsx" */)
}

